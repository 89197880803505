/*
 * Flex UX Framework
 *
 * Filename: checkout_payment.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-payment {
	.continue-button-plp {
		margin-top: 20px;
	}
}