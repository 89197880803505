/*
 * Flex UX Framework
 *
 * Filename: basket-empty.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout-basket-empty {
	text-align: center;

	@include mq("tablet", "max"){
    	margin: 10px;
	}

	.btn-continue-shopping {
		border: none;
	}
}
