/*
 * Flex UX Framework
 *
 * Filename: home-about-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
 // @TODO: push margin to parent
 // @TODO: Use global variables for font-family (see _configs.scss)

.about-us {
	background: white;
	// padding: 18px 30px 12px;
	max-width: none;
	text-align: center;
	line-height: 21px;
	font-family: $standard-font-face;
	background: #fff;
	margin: 24px auto auto auto;
	border-radius: 5px;
	height: 166px;
	max-width: 948px;

	@include mq("phone-wide", "max"){
	    width: 95%;
	    margin: 6px auto auto auto;
	    height:auto;
	    padding-bottom: 15px;
	}
	
	p{
		line-height: 21px;
		color: $primary-font-color;
		font-family: $secondary-font-face;
		margin-bottom: 0;

		@include mq("phone-wide", "max"){
	    	line-height: 1.5;
	    	width: 265px;
	    	margin: auto;
		}
	}

	h1 {
		color: $primary-font-color;
	}

	.copy {
		margin: auto;
		max-width: 604px;
		// padding-bottom: 40px;

		p {
			font-size: 20px;
			font-weight: bold;

			@include mq("phone-wide", "max") {
				font-size: 15px;
			}
		}

		@include mq("phone-wide", "max"){
	    	// margin: 4.3px;
		}
	}

	.title {
		// padding-top: 10px;
		// margin-top: -6px;
		// padding-bottom: 0px;
		letter-spacing: 0;
		text-transform: none;
		line-height: 1;
		padding-top: 20px;
		padding-bottom: 15px;
		font-weight: 700;
		font-size: 70px;

		@include mq("phone-wide", "max") {
			font-size: 30px;
		}

		&:after {
			content: "";
			display: block;
			margin: 0 auto;
			width: 50px;
			border-bottom: 4px solid #EB8223;
			padding-top: 7px;
		}
	}
}
