/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home {

 	main {
 		// background: #f2f2f2;
 	}
 	
 	.page-body {
 		max-width: 972px;
 		// margin:  9px auto auto;
 		margin: auto;
 		// padding: 12px;
 		background: inherit;
 		padding: 0;

 		@include mq("phone-wide", "max") {
		    // padding: 6px;
		    margin-top: 0;
		}
 	}
 }

.spotlight {

	.page-header {
		position: relative;
		padding-top: $header-height;

		@include mq("tablet", "max") {
    		padding-top: 56px;
		}
	}

}
