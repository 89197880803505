/*
 * Flex UX Framework
 *
 * Filename: home-contact-us-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home-contact-us {
    width: 100%;
    background: $brand-color-secondary;
    display: flex;
    justify-content: center;

    &__content {
		max-width: 875px;
		text-align: center;
		box-sizing: border-box;
		background: #fff;
		flex: 1 1 100%;
		// padding: 18px 8PX;
		margin: calc(20px + 20px);
    	margin-right: calc(24px);
    	margin-left: calc(24px);
		margin-left: calc(12px + 12px + 12px);
		margin-right: calc(12px + 12px + 12px);
		border-radius: 5px;
		font-family: $standard-font-face;
		background-color: #fff;

		.contact-us-grid {
			padding: 0;
			max-width: 604px;
    	}

		h1 {
			padding-bottom: 0;
			padding-top: 20px;
			text-transform: capitalize;
			color: $brand-color;
			line-height: 1;
			font-weight: 700;
			padding-bottom: 10px;
		}

    	p {
    		font-size: 14px;
			line-height: 21px;
			// margin-top: 12px;
			margin-bottom: 20px;
			padding-left: 15px;
			padding-right: 15px;
			color: $primary-font-color;
			font-family: $secondary-font-face;

			@include mq("phone-wide", "max") {
				width: 265px;
				margin: auto auto 20px auto;
			}
    	}

    	a {
    		// text-decoration: none;
			// color: #fff;
			// line-height: 24px;
			// font-weight: bold;
    	}
    }
    .home-contact-us__content {
		@include mq("phone-wide", "max"){
	    	margin: 6px;
	    }
	}

}