/*
 * Flex UX Framework
 *
 * Filename: basket-promo.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.checkout.checkout-basket{
    .checkout-basket-promo {
        display: flex;
        flex:none;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0 10px;
         @include mq('phone-wide', 'max') {
            flex-direction: column;
            justify-content: center;
            padding: 10px;

           .mdl-textfield {
               width: 100%;
           }
        }

        .text-wrapper{
        	.icon-wrapper{
        		padding-top: 20px;
        	}
        }

        // Temp (MTL btn classes needs updating)
        &--btn{
        	font-family: $standard-font-face;
    	    font-weight: 700;
    	    letter-spacing: 1px;
            font-size: 16px;
        	padding: 0px 15px;
        	height: 45px;
        }


    }

}


