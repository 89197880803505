/*
 * Flex UX Framework
 *
 * Filename: home-layout-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home.layout-v1{
 	main{
 		// background-color: #fff;
	 	.spotlight{
			.page-header{
				display: flex;
				flex-flow: nowrap;

				@include mq("tablet", "max"){
					flex-direction:column-reverse;
					// height: 100% !important;
				 //    min-height: unset !important;
				 //    max-height: unset !important;

				 //    @include mq("phone-wide", "max"){
				 //    	min-height: 287px !important;
					// 	max-height: 287px !important;
					// }
				}

				&>div{
					&:first-of-type{
						height: inherit;
						@include mq("tablet", "max"){
							height: 287px;

							@include mq("phone-wide", "max"){
								height: 225px;
							}
						}
					}
				}

				.hero-carousel.slick-initialized.slick-slider{
					@include mq("tablet", "max"){
						height: 287px !important;
					}

					@include mq("phone-wide", "max"){
						height: auto !important;
						background-color: rgb(244, 243, 241);
					}	
				}

				.spotlight-hero{
					display: flex;
					position: absolute;
					z-index: 1;
					box-sizing: border-box;
					// height: 450px;
					width: 100vw;
					opacity: 0.95;
					color: #fff;
					top: 150px;

					@include mq('phone-wide', 'max') {
						top: 8.5vh;
					}

					.spot-text {
						display: flex;
						flex-direction: column;
						box-sizing: border-box;
						height: auto;
						max-width: 948px;
						width: 860px;
						color: #fff;
						// font-size: 70px;
						margin: auto;
						justify-content: center;
						text-align: center;

						@include mq('desktop', 'max') {
							margin: 0px auto;
							width: auto;
						}

						@include mq('phone-wide', 'max') {
							align-items: center;
							text-align: center;
							margin-left: 0;
							// padding-bottom: 4vw;
						}

						img {
							box-shadow: 0 0 20px #000;
							height: 72px;
						}

						h1{
							color: #fff;
							font-size: 45px;
							font-weight: bold;
							// text-transform: capitalize;
							line-height: 1.15;
							text-shadow: 0 0 20px #000;
							max-width: 1024px;
							// width: 1024px;
							margin: 0 auto;

							@media only screen and (max-width: 1374px) {
								width: auto;
							}

							@include mq("phone-wide", "max") {
								font-size: 7.5vw!important;
								width: auto;
								line-height: 1.15em;
								margin-bottom: 10px;
							}
						}

						p {
							font-size: 18px;
							text-shadow: 0 0 10px #000;
							padding-top: 26px;
							margin-bottom: 0;
							font-family: "roboto";
							line-height: 1.15;

							@include mq("phone-wide", "max") {
								padding-top: 0;
							}

							@media only screen and (max-width: 600px) {
								padding-left: 2vw;
								padding-right: 2vw;
							}

							@include mq("phone-wide", "max") {
								font-size: 4.5vw!important;
								padding-left: 2vw;
								padding-right: 2vw;
								line-height: 1.15em
							}
						}

						@include mq("tablet", "max"){
							position:inherit;
					   		height: 287px;
					   		width: 100%;
					   		opacity: 1;

					   		@include mq("phone-wide", "max"){
								font-size: 60px;
								height: 225px;
							}
						}

						// @include mq("phone-wide", "max"){
						// 	font-size: 60px;
						// 	height: 225px;
						// }

						&>div{
							display: flex;
							line-height: 0.8;
							
							&:first-of-type{
								font-size: 20px;
							}
						}

						button{
							// margin-top: 50px;
							color: white;
							background-color: $brand-color-btn-primary;
							min-height: 40px;
							margin-top: 30px;
							box-shadow: 0 0 25px #000
						}
					}
				}
			}
	 	}

	 	.page-body{
	 		// max-width: none;
	 		// padding: 0;
	 		// margin: 0;
	 	}
		
		#featured-1{
			.product-list{
				max-width: 100%;
				margin: 0px;

				@include mq("phone-wide", "max"){
					max-width: 95%;
					margin: 0px auto;
				}

				.product-card{
					min-height: 259px;
					max-width: 250px;
					flex-basis: 20%;
					min-width: 200px;
					// margin: 0px;
					// border-right: 1px solid;
    				// border-bottom: 1px solid;
					border-radius: 5px;

					&:first-of-type {
					       justify-content: space-around;
						
						&>h1{
							font-weight: bold;
							text-transform: capitalize;

							@include mq("phone-wide", "max"){
								font-size: 22px;
								padding-bottom: 10px;
							}

						}

						@include mq("tablet", "max"){
							display:block;
							width:100%;
							min-width: 100%;
							min-height: 70px;
						}
					}
						@media (max-width: 1024px){
						&:nth-child(n+5){
							display: none;
						}
					}

					@include mq("phone-wide", "max"){
						min-width: 142px;
						max-width: 169px;

						&:nth-child(n+2){
							display: inherit;
						}

					}

					.product-image{
						flex:inherit;
					}
				}
			}
		}		

 	}

 	.swal2-popup {

 		border-radius: .1em;
		box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;

	 		button {
	 			text-decoration: none;
				color: #fff;
				background-color: #f9b625;
				text-align: center;
				letter-spacing: .5px;
				-webkit-transition: background-color .2s ease-out;
				transition: background-color .2s ease-out;
				cursor: pointer;
				min-height: 45px;
				border: none;
				font-weight: bold;
				font-size: 14px;
				padding:0 16px;
				// line-height: 36px;
				box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);

				&:hover {
					background-color: #f8c046;
				}
	 		}

	 		h2 {
	 			font-size: 2em;
				font-weight: bold;
				margin-bottom: 10px;
				line-height: 100%;
				margin: .5rem 0 1.5rem 0;
	 		}
 	}
 }