/*
 * Flex UX Framework
 *
 * Filename: spotlight_slider.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */ 

.page-header .hero-carousel-overlay .hero-text {
    font-family: $standard-font-face;
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 258px;
    letter-spacing: 4px;
    margin-left: 159px;
    color: white;
    font-size: 115px;
    font-weight: 600;
    line-height: 0.8;
    text-transform: uppercase;
}

.hero-carousel {
    width: 100%;
    max-width: 100%;
    height: 450px;
	min-height: 450px;
	max-height: 450px;
	margin-bottom: 0;

	@include mq('tablet', 'max') {
		min-height: 350px;
		max-height: 350px;
	}

	@include mq('phone-wide', 'max') {
		min-height: 258px;
		max-height: 258px;
	}

	&-overlay {
		.slick-dots {
			left: 0;
			bottom: 12px;

			li {
				button {
					&:before {
						font-size: 16px;
						opacity: 1;
					}
				}

				&.slick {
					&-active {
						button {
							&::before {
								color:  #00699E;
							}
						}
					}
				}
			}
		}
	}

	&.slick-initialized.slick-slider.slick-dotted{
		margin-bottom: 0!important;

	}

    .slick-slider .slick-track, .slick-slider .slick-list {
    	-webkit-transform: translate3d(0, 0, 0);
    	-moz-transform: translate3d(0, 0, 0);
    	-ms-transform: translate3d(0, 0, 0);
    	-o-transform: translate3d(0, 0, 0);
    	transform: translate3d(0, 0, 0);
	}

	.slick-track {
		opacity: 1;
		width: 1354px;
		position: relative;
		top: 0;
		left: 0;
		display: block;

		.hero-slide {
			// background-image: url("store/20190204969/assets/images/spotlights/spot-img.jpg");
			// width: 1354px;
			// position: relative;
			// left: 0px;
			// top: 0px;
			// z-index: 999;
			// opacity: 1;
			background-position: center;
			overflow: hidden;
			display: flex;

			&-1, &-2, &-3, &-4 { 
				height: 450px;
				min-height: 450px;
				max-height: 450px;
				background-size: cover;

				@include mq('tablet', 'max') {
					height: 350px;
					min-height: 350px;
					max-height: 350px;
				}

				@include mq('phone-wide', 'max') {
					height: 250px;
					min-height: 250px;
					max-height: 250px;
				}

			// 	@include mq('phone-small', 'max') {
			// 		height: 200px;
			// 		min-height: 200px;
			// 		max-height: 200px;
			// 	}
			}

			&-1 { 
				height: 450px;
				min-height: 450px;
				max-height: 450px;
				// background-size: contain;
				background-repeat: no-repeat;
				background-color: #CEC9C5;

				@include mq('tablet', 'max') {
					height: 350px;
					min-height: 350px;
					max-height: 350px;
				}

				@include mq('phone-wide', 'max') {
					height: 250px;
					min-height: 250px;
					max-height: 250px;
				}

			// 	@include mq('phone-small', 'max') {
			// 		height: 200px;
			// 		min-height: 200px;
			// 		max-height: 200px;
			// 	}
			}
		}
	}

	// .slick-track::before, .slick-track::after {
	// 	display: table;
	// 	content: '';
	// }

}

.slick-prev, .slick-next {
	display: none !important;
}
