/*
 * Flex UX Framework
 *
 * Filename: checkout-split-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container{
	.checkout-split-row {
		border-bottom: none;

		@include mq("tablet", "min"){
		    padding: 20px;
		}

		&--single-split {
			@include mq("tablet", "max"){
		    	flex-flow: column;
			}
		}

		&.checkout-split-row--controls {
		    justify-content: flex-end;
		}

		.checkout-split-cell--controls-top {
		    margin-bottom: 20px;
		}

		.checkout-split-cell--controls-top, .checkout-split-cell--controls-bottom {
		    justify-content: flex-end;
		    flex: 1 1 100%;

		    @include mq("tablet", "max"){
			    flex-flow: column;
			    flex-wrap: wrap;
			}

			button:not(:last-child){
			    @include mq("tablet", "min"){
				    margin-right: 20px;
			   }

			   @include mq("tablet", "max"){
				    margin-bottom: 20px;
				} 
			}
		}
	}
}