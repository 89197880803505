/*
 * Flex UX Framework
 *
 * Filename: category.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.cat-banner {
	display: flex;
	p{
		margin: auto;
	}
}

.category-list {
	main{
		background-color: #f2f2f2;
	}

	.page-header {
		height: 250px!important;

		.category-header-img {
			height: 250px;
			background: url("../images/CRU_desktop_cat_250_1350.JPG");
			background-size: cover!important;
			background-position-x: center!important;

            @include mq('tablet-small', 'max') {
                background: url("../images/CRU_tablet_cat_200_768.JPG");
                height: 200px;
            }

            @include mq('phone', 'max') {
                background: url("../images/CRU_mobile_cat_150_400.JPG");
                height: 150px;
            }

            @include mq('phone-small', 'max') {
                background: url("../images/CRU_mobile_small_cat_100_321.JPG");
                height: 100px;
            }
        }

		.category-fresh-swag {
			display: flex;
			align-items: flex-end;
			justify-content: center;

			background: url("../images/CRU_desktop_fresh_cat_250_1350.JPG");
			background-size: cover!important;
			background-position-x: center!important;

			* {
				box-sizing: border-box;
			}

			.cat-banner {
				align-items: center;
				width: 100%;
				max-width: 890px;
				margin-right: 15px;
				margin-left: 15px;
				margin-bottom: 25px;
				padding: 6px 12px;

				background-color: rgba(255, 255, 255, .8);
				border: 1px solid #5A5A5A;
				border-radius: 5px;
			}

			.img-wrapper {
				margin-right: 10px;
			}

			p {
				color: #5A5A5A;
				font-family: $standard-font-face;
				font-size: 18px;
				font-weight: 700;
				text-align: center;
			}

			a {
				color: $brand-color !important;
			}

			@include mq('tablet-small', 'max') {
                background: url("../images/CRU_tablet_fresh_cat_200_768.JPG");
            }

			@include mq("phone-wide", "max") {
				&.category-header-img {
					height: 200px;
				}
			}

            @include mq('phone', 'max') {
                background: url("../images/CRU_mobile_fresh_cat_150_400.JPG");
            }

            @include mq('phone-small', 'max') {
                background: url("../images/CRU_mobile_small_fresh_cat_100_321.JPG");
            }

			@media only screen and (max-width: 530px) {
				.img-wrapper {
					min-width: 33px;
				}

				br {
					display: none;
				}
			}

			@media only screen and (max-width: 350px) {
				p {
					font-size: 16px;
				}
			}
		}
	}

	.page-body {
		font-family: $standard-font-face;
		font-size: 13px;
		display: flex;
		flex-wrap: wrap;

		.products-container {
			flex-grow: 1;
			flex-basis: calc(100% - 260px);
			// border-bottom: 1px solid rgba(226,226,226,0.5);
			order: 4;
			margin-right: -12px;
			overflow-x: hidden;

			@include mq('tablet', 'max') {
				margin-right: 0;
				flex-basis: 100%;
    			margin: 24px -12px 0;
			}

			@include mq("phone-wide", "max") {	
				margin: 6px -6px 0;
				padding: 5px;
				padding-top: 0;
			}
		}

		.grid-container {
    		display: flex;
    		flex-flow: row nowrap;
		}

		@include mq('tablet', 'max') {
			flex-direction: row-reverse;
		}
		@include mq("phone-wide", "max") {
		    padding: 15px;
		    margin-top: 0;
		}
	}

	.products-header {
    	flex: 3 75%;
    	order: 1;
    	height: 41px;
    	padding-bottom: 5px;
    	margin-bottom: 15px;
    	
    	@include mq('tablet', 'max') {
			align-self: center;
			order: -1;
	    	flex: 2 2 50%;
	    	display: flex;
	    	justify-content: flex-end;
	    	margin-bottom: 0;
		}	

    	@include mq('tablet', 'min') {
    		display: flex;
			flex-flow: row;
    		flex-wrap: nowrap;
			flex-wrap: nowrap;
			flex: 4 4 100%;
    	}

    	.title {
    		flex: 1 1 50%;
			font-size: 30px;
			height: 62px;
			font-weight: 700;
			font-family: $standard-font-face;
			color:#5A5A5A;

			@include mq('tablet', 'min') {
				line-height: 42px;
    			// margin-left: 240px;
			}
    	}

	}

	ul.pagination-numbers li {
	    &:hover {
	        background-color: #ffffff !important;
	    }
	}

}

.ministry-list {

	.products-container {
		margin-top: 10px;

		@include mq("phone-wide", "max") {
			margin: 24px -12px 0 !important;
		}
	}
	.products-header {
			
		padding-top: 65px;

			@include mq("tablet", "max") {
				padding-top: 9px;
			}

			h1 {
				   display: block;
				   margin: 29px auto 0;
				   font-weight: 700;
				   line-height: 35px;
			}
	}
	

	.brands {
	    display: flex;
	    flex-flow: row wrap;
	    overflow: hidden;
	    justify-content:center;
	    width: 100%;


		.brand-wrap {
			background-color: #fff;
			display: inline-block;
			width: 220px;
			height: 125px;
			flex-basis: 25%;
			margin: 10px;
			max-width: 220px;
			text-align: center;
			border-radius: 5px;

			@include mq("phone-wide", "max") {
                flex-basis:45%;
                margin:5px;
            }

			a {
				display: block;
				width: 100%;
				height: 100%;
			}

			img {
				width: 60%;
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}
		}

		.cru-soon-movement-logo img {
		    width: 75% !important;
		    height: 75% !important;
		    margin-top: 8%;
		}
	}
}

.brand-wrap{
	display: flex;
	max-width: 215px;
	height: 50px;
	justify-content: center;	

	img{
		max-width: 100%;
	}

}