/*
 * Flex UX Framework
 *
 * Filename: sort-by.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


#sort-by.btn {
    align-self: flex-end;
    padding: 3px 7px;
    padding-right: 10px;
    letter-spacing: 0;
    font-size: 14px;
    line-height: normal;
    text-transform: uppercase;
    font-weight: bold;
    order: 2;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    font-family: $secondary-font-face;
    border-radius: 5px;
    color:#5A5A5A;
    // flex-basis: 92px;

    @include mq('tablet', 'max') {
    	padding: 5px 10px;
	    border-radius: 5px;
	    order: 3;
    }

    @include mq('tablet', 'min') {
    	padding: 5px 15px;
	    min-width: 85px;
	    // height: 41px;
    }

    @include mq("phone-wide", "max") {
        padding: 4px 10px;
        margin-right: 6px;
    }

    &::after {
    	font-family: 'Material Icons';
		content: '\e314';
		display: inline-block;
		transform: rotate(-90deg);
		font-size: 22px;
		color: $brand-color;
		position: relative;
    }

}

.sort-by-container {
    font-family: $secondary-font-face;
    font-size: 14px;

    .mdl-menu__container {
        left:initial !important;
        top:initial !important;
        margin-left: -36px;

        .mdl-menu__item:hover {
            background-color: #f2f2f2;
        }

        a {
            text-decoration: none;
            color: $primary-font-color;

            &:hover {
                text-decoration: underline;
                color: $brand-color;
            }
        }

        @include mq('tablet', 'max') {
            margin-left: -61px;
        }
    }
}