/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

$body-max-width:                    1175px;


//
// General: Color
//

$brand-color:                       #00699E;
$brand-color-secondary:             #F5BE19;;
$brand-color-btn-primary:           #00699E;
$brand-color-btn-primary-hover:     #014B71;
$brand-color-btn-secondary:         #fff;
$brand-color-btn-secondary-hover:   #fff;

$primary-font-color:                #5a5a5a;
$secondary-font-color:				#143055;
$h1-font-color:                     $primary-font-color;
$h2-font-color:                     $primary-font-color;

$main-background-color:				#F4F3F1;


//
// General: Font
// 

$h1-font-size:                      30px;
$h2-font-size:                      21px;
$standard-font-face:                "source-sans-pro", serif;
$secondary-font-face:				"source-sans-pro", serif;
$drawer-font:                       "source-sans-pro", serif;


//
// Header
//

$header-background:                	#fff;
$header-top-background:				#fff;
$header-height:                     182px; 
$header-top-height:                 74px;
$header-main-height:                44px;

$header-tablet-height:              65px;
$header-phone-height:               65px;
$header-logo-padding:               25px;
$header-logo-height:               	47px;
$header-logo-width:               	75px;
$header-minicart-badge-color:       $brand-color-secondary;
$header-minicart-badge-border:      $brand-color-secondary;
$header-top-link-hover-border:      2px solid #000104;
$header-icons-color:                #5a5a5a;
$header-search-font-color:          $header-icons-color;
$header-search-underline-color:     $header-icons-color;


//
// Mobile Drawer Menu
//

$drawer-mobile-background:          #fff;


//
// Footer
//

$footer-background:                 #323232;
$footer-secondary-background:		#5A5A5A;
