/*
 * Flex UX Framework
 *
 * Filename: custom_orders.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.custom-orders {

	main {
		background: #fff;
	}

    .page-header {
        // height: 0;

        .secondary-header-img {
            height: 250px;
            background: url("../images/CRU_desktop_secondary_250_1350.JPG");
            background-size: cover!important;
            background-position-x: center!important;

            @include mq('tablet-small', 'max') {
                background: url("../images/CRU_tablet_secondary_200_768.JPG");
                height: 200px;
            }

            @include mq('phone', 'max') {
                background: url("../images/CRU_mobile_secondary_150_400.JPG");
                height: 150px;
            }

            @include mq('phone-small', 'max') {
                background: url("../images/CRU_mobile_small_secondary_100_321.JPG");
                height: 100px;
            }
        }
        .tertiary-header-img {
            height: 250px;
            background: url("../images/ministry-resources_banner.png");
            background-size: cover!important;
            background-position-x: center!important;
            @media only screen and (max-width:380px) {
                background-position-x: 100% !important;
            }
        }
    }

	.page-body {

    	max-width: 600px;
    	margin: auto;
        margin-top: auto;
    	padding: 12px;
        padding-bottom: 50px;
    	margin-top: 9px;
    	font-size: 14px;
    	line-height: 21px;

        @include mq('phone-wide', 'max'){
            // padding: 15px;
            margin-top: 0;
        }
        .linkWrapper {
            display: flex;
            justify-content: center;
            .storeLink {
                display: flex;
                justify-self: center;
                align-items: center;
                text-decoration: none;
                color: #FFF !important;
            }
        }
	}
}