/*
 * Flex UX Framework
 *
 * Filename: item-romance-mobile.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.product-description-text {
    font-family: $secondary-font-face;
    line-height: 1.8;

	@include mq("tablet", "max"){
		line-height: 2;
	}
}

.product-description-accordions {
	@include mq("tablet", "max"){
		line-height: 2;
	}

	@include mq('phone-wide', 'min'){
		height: 0;
	    overflow: hidden;
	}

	.product-description-text{
		padding: 15px;
	}

	.mdl-accordion > .mdl-button {
	    width: auto !important;
	    font-weight: bold;
	    font-size: 14px;
	    font-family: $secondary-font-face;
	    color: $primary-font-color;
	}

	p{
		line-height: 1.5em;
		font-weight: 400;
	}

}