/*
 * Flex UX Framework
 *
 * Filename: faqs.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.faqs {

	main {
		background: #fff;
	}

    .page-header {

        .secondary-header-img {
            height: 250px;
            background: url("../images/CRU_desktop_secondary_250_1350.JPG");
            background-size: cover!important;
            background-position-x: center!important;

            @include mq('tablet-small', 'max') {
                background: url("../images/CRU_tablet_secondary_200_768.JPG");
                height: 200px;
            }

            @include mq('phone', 'max') {
                background: url("../images/CRU_mobile_secondary_150_400.JPG");
                height: 150px;
            }

            @include mq('phone-small', 'max') {
                background: url("../images/CRU_mobile_small_secondary_100_321.JPG");
                height: 100px;
            }
        }
    }



	.page-body {

    	max-width: 600px;
        padding-bottom: 50px;

        @include mq('phone-wide', 'max') {
            padding: 15px;
            margin-top: 0;
        }
	}
}