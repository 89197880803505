/*
 * Flex UX Framework
 *
 * Filename: breadcrumbs.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.breadcrumb {
    height: 42px;
    line-height: 42px;
    background: #fff;
    font-size: 14px;
    font-weight: normal;
    font-family: $secondary-font-face;
    white-space: normal;
    overflow: hidden;
    padding-left: 25px;
    position: relative;
    color: $primary-font-color;
    overflow: hidden;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    border-bottom: 1px solid #e6e6e6;

    @include mq("phone-wide", "max") {
        font-size: 13px;
    }

    a, span {
        margin-right: 8px;
        text-decoration: none;
        z-index: 1;
    }

    span:last-of-type {
    	// color: black;
		// font-weight: 700;
    }

    @include mq("tablet", "max"){
        padding: 0 15px;
    }
}

.checkout{
    .page-body{ 
        h1{
            @include mq("tablet", "max"){
                font-size: 29px;
            }
        }

        @include mq("phone-wide", "max"){
            padding: 15px !important;
            margin-top: 0  !important;
        }
    }
}