/*
 * Flex UX Framework
 *
 * Filename: home-featured-banner-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


 .home .main-feature {
    background: url("../images/banner-items.png") no-repeat scroll right;
    background-color: #ccc;
    border-radius: 0;
    background-position: 95% 50%;

    @media only screen and (max-width: 767px) {
    	background-size: 52vw;
    }

    @media only screen and (max-width: 767px) and (min-width: 600px) {
    	height: 295px !important;
		min-height: 295px !important;
		max-height: 295px !important;
    }

    @include mq("tablet-small", "max") {
		// margin: auto auto 0;
		padding: 0;
	}
    
	.product-info {
    	// text-align: center;
		display: flex;
		flex-flow: column nowrap;
		// align-items: center;
		// justify-content: center;
		max-width: 600px;
		margin: auto auto auto	3.25vw;
		align-self: flex-start;

		@include mq("tablet", "max") {
			width: 500px;
		}

		@include mq("tablet-small", "max") {
			max-width: 50vw;
			width: auto;
		}

    }

 	> div {
	    // width: 50%;
    	display: flex;
    	flex-flow: column nowrap;
		@include mq("tablet-small", "max"){
		    width: 100%;
		    height: 100%;
		    // padding-bottom: 3.25vw;
		}

    }

    h1 {
		color: $brand-color;
		font-family: $standard-font-face;
		// text-shadow: 2px 2px 7px #333;
		font-size: 50px;
		// line-height: 50px;
		text-transform: capitalize;
		font-weight: bold;
		margin-bottom: 19px;


		@include mq("tablet-small", "max"){
			position: relative;
		    // top: -8px;
		    font-size: 8vw;
		    margin-bottom: 0;
		    // line-height: 50px;
		}

    	@include mq("desktop", "min"){
		    position: relative;
		    top: -8px;
		    font-size: 50px;
		    line-height: 50px;
		}
	}

	p{
		color: #333;
		font-size: 21px;
		margin-bottom: 31px;

		@include mq("tablet", "max") {
			max-width: 35vw;
			font-size: 18px;
		}

		@include mq("phone-wide", "max") {
			font-size: 4vw;
			margin-bottom: 0;
		}
	}

	button {
		color: #fff;
		background-color: $brand-color-secondary;
		border-radius: 0;
		font-weight: bold;
		width: 155px;
		height: 45px;
		padding: 6px 0;
		margin-top: 4px;
		
		@include mq('tablet-small', 'max') {
			margin-bottom: 3.25vw;
		}

		@include mq('phone', 'max') {
			height: 13vw;
			width: 35vw;
			// margin-bottom: 3.25vw;
		}
	}
}

body.home h1{
	margin: 0;
	
	@include mq("tablet", "max"){
    	// font-size: 22px !important;
	}
	
}


.home .product-info--right {
  @include mq("tablet", "min"){
	    align-self: flex-end !important;
	    margin-right: 70px;
	}
}	


