/*
 * Flex UX Framework
 *
 * Filename: header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


// NOTE: This file makes exceptions to the standard skinning process by determining its
//       own position. This is because the header is in a fixed position at all times.

header {
	position: fixed;
	width: 100%;
	background: #fff;
	z-index: 2;
	height: $header-height;
	// border-bottom: 1px solid #ccc;
    @include mq('tablet', 'min') { 

		&::before {
			content: "With global shortages impacting our industry we apologize for any shipment delays!";
			display: block;
			color: red; 
			padding: 20px; 
			display: flex;
			justify-content: center;
			align-items: center; 
			font-size: 20px;  
			font-weight: 700;   
		}

    }   

	@include mq('tablet', 'max') {
		height: 56px;
	}

	.site-header {
		height: $header-top-height;
		background: $header-top-background;
		font-weight: normal;
		font-family: $standard-font-face;
		font-size: 10px;
		color: #333;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		border-top: 5px solid #f5BE19;
		border-bottom: 1px solid #bebebe;

		@include mq('tablet', 'max') {
			display: none;
		}

		div {
			display: flex;
		}

		&__welcome {
			padding: 7px 0;
			padding-left: 158px;
			font-size: 15px;
			text-transform: none;
			color: $primary-font-color;
			margin-top: 7px;
		}

		&__nav-menu {
			margin: 0;
			// margin-right: 10px;
			margin-top: 5px;
			padding-right: 80px;

			li {
				display: inline-block;
				list-style-type: none;
				font-size: 14px;
				padding-left: 13px;
				padding-right: 13px;
				height: 35px;
				line-height: 35px;
				cursor: pointer;
				font-weight: 700;

				a {
					color: $primary-font-color;
					text-decoration: none;
					text-transform: capitalize;
					font-weight: normal;
					display: inline-block;
					height: 100%;

					&:hover {
						text-decoration: underline;
					}
				}

			}

		}

		.site-header__left {
			position: absolute;
			left: 0;
			top: 0;
			max-height: $header-top-height;

			@media only screen and (min-width: #{$body-max-width}) {
				left: calc((100vw - #{$body-max-width}) / 2);
			}

			.mdl-textfield {
				padding: 0;
			}
		}

	}

	.main-nav {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
		font-weight: 700;
		padding-left: 0px;
		padding-right: 20px;
		height: $header-main-height;
		color: #333;
		font-family: $standard-font-face;
		background: $header-background;

		@include mq('tablet', 'max') {
			height: $header-tablet-height;
			padding-right: 49px;
			border-top: 3px solid #f5BE19;
		}

		@include mq('phone-wide', 'max') {
			height: $header-phone-height;
			padding-right: 49px;
		}

		&__left, &__right {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

	}

	.main-nav__right {
		flex-flow: row nowrap;
		margin-bottom: 62px;

		@include mq('tablet', 'max') {
			margin-bottom: 0;
			margin-top: 0;
		}

		.mdl-button--search {
			top: 0;
			bottom: 0;
			// right: 0;
			// margin-left: 216px;

			.material-icons {
				font-size: 27px;
				height: 27px;
				width: 27px;
			}

		}

		.mdl-textfield--expandable {
			padding: 0;
			// width: 215px;
		}

		#searchField {
			// width: 170px;
			// border-bottom: none;
		}

		.search-desktop {
			display: flex;
			margin: 0;
			align-items: flex-end;
			flex-flow: column-reverse;
		}

		.search-textfield-container {
			position: relative;
			// height: 30px;
			// width: 250px;
			// border-bottom: 1px solid #ccc;
			// border-radius: 18px;

			.mdl-textfield {

				&__label::after {
					background-color: $header-search-underline-color !important;
					left: 0;
					visibility: visible;
					width: 100%;
				}

				&__expandable-holder {
					// margin-left: 30px;
				}
			}

			.mdl-textfield__input {
				color: $header-search-font-color;
				// border-bottom: 1px solid $header-search-font-color;
				border-bottom-color: $header-search-font-color;
			}

			@include mq('tablet', 'max') {
				display: none;
			}

		}

		.material-icons {
			margin-right: 1px;
			color: $header-icons-color;
			transform: translate(-14px, -11px);
		}

		.cart {
			margin-bottom: 22px;

			.cart-container {
				width: 34px;
				height: 34px;

				@include mq('tablet', 'max') {
					top: 9px;
				}

				.material-icons::after {
					// border: $header-minicart-badge-border;
					width: 12px;
					height: 12px;
					top: -3px;
					right: -1px;
					font-size: 9px;
					background: $header-minicart-badge-color;
					color: #143055;
				}

			}

			.checkout-btn {
				height: 36px;
			}

			.mdl-menu {
				&__container {
					right: -15px;
					top: 44px;
					max-height: calc(100vh - 108px);
					margin-top: 8px;

					@include mq('tablet', 'min') {
						height: 295px !important;
					}

					.shopping-cart {
						padding: 0;
						width: 430px;
						max-width: 100vw;
						display: flex;
						flex-flow: column nowrap;
						color: $brand-color;
						font-weight: 700;

						.cart-header {
							padding: 0 20px;
							border-bottom: 1px solid rgba(226, 226, 226, 0.5);
							height: 80px;
							display: flex;
							align-items: center;
							box-sizing: border-box;

							@include mq('tablet', 'max') {
								height: 50px;
								min-height: 50px;
							}

							.cart-title {
								font-size: 23px;
								// text-transform: uppercase;
								line-height: 1.75;
								color: #5A5A5A;

								@include mq('tablet', 'max') {
									font-size: 19px;
								}

							}

						}

						.cart-item-container {
							overflow-y: auto;
							flex-grow: 1;
							padding: 0 20px;
							max-height: 472px;

							.cart-item {
								display: flex;
								align-items: center;
								height: 135px;
								box-sizing: border-box;
								position: relative;
								font-family: $secondary-font-face;

								.cart-item-img {
									display: flex;
									width: 85px;

									img {
										margin: auto;
										max-height: 85px;
										max-width: 85px;
									}

								}

								.cart-item-text {
									flex: 1;
									display: flex;
									flex-wrap: wrap;
									margin-left: 30px;

									.cart-item-name {
										text-transform: uppercase;
										flex: 2 100%;
										margin-bottom: 25px;

										a {
											font-weight: 700;
											font-size: 13px;
											line-height: 16px;
											text-decoration: none;
											color: $primary-font-color;
										}

									}

									.cart-item-quantity {
										flex: 1 50%;
										font-weight: 400;
										color: $primary-font-color;
									}

									.cart-item-price {
										flex: 1 50%;
										color: $brand-color;
									}

								}

							}

						}

						.cart-footer {
							border-top: 1px solid rgba(226, 226, 226, 0.5);
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 20px;
							height: 80px;

							@include mq('tablet', 'max') {
								height: 59px;
								min-height: 59px;
							}

							.cart-subtotal {
								font-size: 18px;
								font-family: $secondary-font-face;
								font-weight: bold;

								@include mq('tablet', 'max') {
									font-size: 15px;
								}

							}

							.checkout-btn:hover {
								background-color: $brand-color-btn-primary-hover;
							}

							.mdl-button--colored {
								font-family: $standard-font-face;
								font-size: 14px;
								font-weight: 700;
								// letter-spacing: 1px;
								background: $brand-color;
								color: white !important;
								text-transform: uppercase;
							}

						}

					}

				}

			}

		}

	}

	.main-nav__left {
		// flex-flow: row wrap;
		// padding: $header-logo-padding;

		@include mq('tablet', 'max') {
			padding: 0;
		}

		@include mq('desktop', 'max'){
			flex-flow: row wrap;
		}

		.desktop-nav {
			@include mq('tablet', 'max') {
				display: none !important;
			}

			display: flex;
			flex-flow: row wrap;
			padding-top: 9px;
			margin: 0px;
			padding: 0px;
// 			width: 100%;
// 			padding-left: 140px;
			position: relative;
// 			top: $header-logo-padding;

			ul:first-of-type {
				position: absolute;
			}

			& > ul {
				margin-top: -25px;
				padding-left: 0px;
				flex: 1 1 100%;
				display: flex;
			}

			& > ul > li {
				position: relative;
				display: inline-block;
				list-style: none;
				// min-width: 164px;
			}

			& > ul > li[data-subnav] {
				position: relative;
				display: inline-block;
				list-style: none;

				max-width: 163px;
				min-width: 145px;
				// margin-right: 20px;
				margin-left: 20px;
			
				.nav-handle {
					// text-align: left;
					position: relative;
					margin-left: 0;

					a span {
						// margin-right: 12px;
						margin-left: 12px;

						&:first-of-type {
							// margin-right: 5px;
							padding-left: 20px;
						}
					}
				}

				> .nav-handle {

					&:hover {
						color: $brand-color;

						::after {
							color: $brand-color;
						}
					}

					::after {
						font-family: 'Material Icons';
						content: '\e5d2';
						display: inline-block;
						// transform: rotate(-90deg);
						font-size: 24px;
						color: #5A5A5A;
						position: absolute;
						left: 10px;
						top: 3px;
						// clip: rect(6px, 22px, 32px, 0px);
						margin-left: 12px;
						// display: none;
					}

				}

				.sub-nav {
					z-index: 10;
					padding: 8px 0;
					display: none;
					opacity: 0;

					.mdl-button {
						height: 30px !important;
					}

					.sub-nav-item {
						position: relative;
						margin: 0;
						cursor: pointer;
						display: block;
						white-space: nowrap;
						text-align: left;

						&:hover {
							// background: #F2F2F2 !important;
							a {
								color: #43807A;
							}
						}
					}

					.nav-handle {
						padding: 0 24px;
					}

				}

				.sub-nav [data-subnav] {
					& > .nav-handle {
 						padding-right: 45px;
						width: calc(100% - 69px);

						::after {
							font-family: 'Material Icons';
							content: '\e5cc';
							display: inline-block;
							font-size: 22px;
							color: #43807A;
							position: absolute;
							right: 10px;
							top: -3px;
							clip: rect(6px, 22px, 32px, 0px);
						}

					}

					.subSubNavContainer {
						display: block;
						position: absolute;
						top: 0;
						left: 99.5%;
					}

				}

			}

			& > ul > li {
				.nav-handle {
					//	text-align: left;
					// border-radius: 0;
					//min-width: 101px;
					display: flex;
					flex-flow: column;
					justify-content: center;
					height: 41px;
    				margin-top: 5px;

					& > a {
						// line-height: 0;
					}

				}

				a {
					color: #000; 
					text-transform: none;
					text-decoration: none; 
					font-family: $standard-font-face;
					font-size: 14px;
					text-align: left;

					&:hover {
    					color: $brand-color !important;
    				}
				}

				.nav-handle-line-1 a {
					font-size: 15px;
					font-weight: bold;
					text-align: center;
					white-space: nowrap;
					text-transform: uppercase;
				}

			}

		}

		.logo {
			// max-width: $header-logo-width;
			max-height: $header-logo-height;
			height: $header-logo-height;
			margin-bottom: 50px;
			width: 75px;
			position: absolute;
			top: 69px; 
			left: 24px;

			@include mq('tablet', 'max') {
				max-height: calc(#{$header-tablet-height} - 10px);
				width: auto;
				margin-bottom: 0;
				top: 10px;
				left: 14px;
			}

			// @include mq('phone-wide', 'max') {
			// 	max-height: calc(#{$header-phone-height} - 35px);
			// 	width: auto;
			// 	// margin-left: $header-logo-padding;
			// }

		}

	}
}

.mobile-drawer-nav {
	position: fixed;
	z-index: 100;
	pointer-events: none;
	font-family: $standard-font-face;

	.drawer-logo-container {
		background: $drawer-mobile-background;
		margin-top: 0;
		height: 56px;
		padding: 0px;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: space-between;

		.close-menu{
			position: absolute;
			right: 5px;
			top: 5px;
			color: #5A5A5A !important;
			cursor: pointer;
			&:hover{
				color: #00699E !important;
			}
		}

		.logo--desktop {
			height: 54px;
			width: 200px;
			@include mq("tablet", "min") {
				padding: 25px 5px 5px 0px;;
			}

			@include mq("phone-wide", "max") {
				max-width: 125px;
				height: 50px;
			}
		}

		@include mq('tablet', 'max') {
			padding-top: 5px;
		}
	}

	.mobile-nav-page-controller {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		-webkit-transition: height 0.3s;
	}

	.mobile-nav-page {
		position: absolute;
		width: 100%;
		-webkit-transition: margin 0.3s;
	}

	.mdl-layout__drawer {
		&.is-visible {
			transform: translateX(0);
			pointer-events: all;
		}

		background: #fff;
		font-weight: bold;
		border: none;
		text-transform: uppercase;
		width: 280px;
		transform: translateX(-280px);

		&-button {
			color: $header-icons-color;
			top: 7px;
			left: auto;
			right: -4px;
			pointer-events: all;
		}

	}

	.mdl-navigation {
		padding-top: 0;
		font-family: $secondary-font-face;
		font-weight: 700;
		text-transform: Capitalize;

		ul {
			font-size: 15px;
			padding-left: 20px;
		}

	}

	ul {
		.mobile-nav-handle {
			a {
				color: $primary-font-color;
				font-weight: 700;
			}

		}

		[data-subnav] {
			i.material-icons {
				color: #5A5A5A;
			}

		}

		li {
			display: flex;
			margin-bottom: 10px;
		}

	}

	ul.top-buttons {
		li.top-btn {
			a {
				color: $brand-color !important;
				font-weight: 700;
			}

		}

	}

	.back-button {
		cursor: pointer;
		padding-left: 20px;
		margin-bottom: 8px;
		display: flex;
		line-height: 24px;

		i.material-icons {
			color: $brand-color;
			margin-right: 10px;
		}

	}

	.sub-nav-title {
		font-weight: bold;

		&:before {
			content: "•";
			font-size: 23px;
			line-height: 22px;
			color: $brand-color;
			margin-right: 6px;
		}

	}

	.page-right {
		margin-left: 100%;
	}

	.page-left {
		margin-left: -100%;
	}

	.search-textfield-container {
		padding: 0 20px;
		padding-right: 15px;

		.mdl-textfield {
			padding: 27px 0;
			width: 100%;
			margin-left: -11px;
			padding-top: 14px !important;
		}

		.mdl-textfield__input {
			position: relative;
			top: 6px;
			text-align: left;
		}

		input {
			width: calc(100% - 40px);
			margin-left: 40px;
			border-bottom: 1px solid lightgray;
			border-radius: 0;
		}

		.mdl-textfield__label {
			width: calc(100% - 40px);
			margin-left: 40px;
		}

		.mdl-textfield.is-dirty, .mdl-textfield.is-focused {
			.mdl-textfield__label {
				color: $brand-color !important;
			}

		}

		i {
			color: $brand-color;
		}

	}

	// .search-textfield-container
}

//.mobile-drawer-nav

nav ul li a {
	text-decoration: none;
}

.page-header {
	padding-top: $header-height;
	background: #fff;
	display: block;

	@include mq("tablet", "max") {
		padding-top: 68px;
	}

	@include mq("tablet", "max") {
		flex-flow: column-reverse nowrap;
	}
}

// .page-header, .page-header .category-header-img {
// 	@include mq('phone', 'max') {
// 		height: 100px;
// 	}
// }
