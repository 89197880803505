/*
 * Flex UX Framework
 *
 * Filename: login-box.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.login-box {
    font-family: $standard-font-face;

    .forgot-password-link {
    	padding-bottom: 15px;
    }

    label {
		text-align: left;
	    padding-left: 10px;
    }

    input {
	    text-align: left;
	    font-family: $standard-font-face;
	    font-size: 14px;
	    color: #666666;	    
	}

	a {
	    font-size: 14px;
	    text-decoration: none;
	    color: $brand-color;

	    &:hover {
	    	color: #5b6e88;
	    }
	}
	.mdl-grid {
	    justify-content: center;
	    text-align: center;
	    align-items: center;
	    padding-bottom: 50px;
	    padding-top: 25px;
	}

	.mdl-textfield {
	    color: #BFBFBF;
 
		&.is-dirty {
		    color: #333;
		}
		
		&__input {
		    padding-left: 3px;
		    font-family: $standard-font-face;
		    font-size: 14px;
		}

		&.is-focused,
		&.is-dirty {
			.mdl-textfield__label{
				color:$brand-color !important;

			}
		}

		.mdl-textfield__label:after {
	    	background-color: $brand-color !important;
		}
	}

	.material-icons {
	    font-size: 12px;
	}

	.login-button {
	    font-weight: 700;
	    font-size: 16px;
	    width: 300px;

	    a {
	    	font-weight: 700;
	    	font-size: 16px;
	    	width: 300px;

	    	&:hover {
	    		color: #231F20;
	    	}
	    }
	}

	#register-btn {
	    width: 300px;
	    color: #000;
	    font-weight: bold;
	    font-size: 16px;
	    letter-spacing: 1px;
	}

}



