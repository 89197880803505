/*
 * Flex UX Framework
 *
 * Filename: home-featured-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// @TODO: rename markup
// @TODO: convert @media queries to use the mq mixin


#featured-1 {
  width: 100%;
  // background: #F2F2F2;
  // display: flex;
  // flex-flow: column;
  // justify-content: center;
  min-height: 398px;
  height: auto;
  display: flex;
  // display: block;
  margin: 24px auto 0;
  max-width: 1330px;

    @include mq("phone-wide", "max"){
      margin: 10px auto 0;
    }

  &__content {
    padding-top: 40px;
    width: 100%;
    max-width: 1250px;
    margin: auto;
    // display: block;
    display: inline-flex;
    justify-content: center;

    @include mq("phone-wide", "max"){
      width: auto;
      padding-left: 5px;
      padding-right: 5px;
    }

    .product-card {
      margin-bottom: 0;
      margin-top: 20px;
    }

    h1 {
      color: #5A5A5A;
      text-align: center;
      line-height: 1;
      font-weight: 700;
      text-transform: capitalize;
    }
  }
}

.main-feature {
   @media only screen and (max-width: 767px) and (min-width: 400px) {
    height: 275px !important;
    min-height: 275px !important;
    max-height: 275px !important;
  }

  @include mq("tablet-small", "max") {
    height: auto;
    padding: 10px 38px 38px;
    flex-flow: column nowrap;
  }

  @include mq("phone-wide", "max") {
    margin: 6px;
  }

  height: 300px;
  margin: 12px;
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  border-radius: 5px;
  overflow: hidden;


}

.btn-featured-products {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.28);
}
