/*
 * Flex UX Framework
 *
 * Filename: missing_custprops.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.missing-custprops {

	.page-body {
		text-align: center;

		.customer-property-field {
			display: block;
			margin: 0 auto;
		}
	}

}