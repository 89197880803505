/*
 * Flex UX Framework
 *
 * Filename: item.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.product-detail {
	
	main{
		background-color: #f2f2f2;
	}

	.page-header {
		
		// background: url("../images/CRU_desktop_item_250_1350.JPG");

		.item-header-img {
			height: 250px;
			background: url("../images/CRU_desktop_item_250_1350.JPG");
            background-size: cover!important;
            background-position-x: center!important;

            @include mq('tablet-small', 'max') {
                background: url("../images/CRU_tablet_item_200_768.JPG");
                height: 200px;
            }

            @include mq('phone', 'max') {
                background: url("../images/CRU_mobile_item_150_400.JPG");
                height: 150px;
            }

            @include mq('phone-small', 'max') {
                background: url("../images/CRU_mobile_small_item_100_321.JPG");
                height: 100px;
            }
        }
	}

	&-page-container {
    	width: 100%;
    	padding: 0;
    	max-width: 1115px;
    	margin: 25px auto 12px;
    	font-family: $standard-font-face;

    	@include mq("tablet", "max"){
    		flex-direction: column;
		    width: calc(100% - 20px);
		    margin-top: 10px;
		    margin: 10px auto 6px;
		}

		@include mq("phone-wide", "max") {
			margin-top: 10px;
		}
	}

	&-panel {
    	border-radius: 5px;
    	padding: 0;
    	background: none;
    	display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		// font-family: $standard-font-face;

		@include mq("tablet", "max"){
			flex-direction:column;
		}
	}

	.product-main-container {
		@include mq("tablet", "min") {
			margin-right: 25px;
    		width: 600px;
    	}
	}

	.product-details-container {
    	width: calc(100% - 625px);
    	background: white;
    	padding: 20px;
    	border-radius: 5px;
    	box-sizing: border-box;
    	// height: min-content;
    	height: 100%;

    	@include mq("tablet", "max"){
    		width: 100%;
    		margin-top: 10px;
    	}

    	.select-option-btn:after {
		    font-size: 24px;
		}
	}

}