/*
 * Flex UX Framework
 *
 * Filename: forgot_password_login.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.forgot-password {

	.page-header, .page-header .category-header-img {
		@include mq('phone', 'max') {
			height: 0px;
		}
	}

	.page-body {

		padding-bottom: 50px;

		h1 {
			font-size: 30px;
			font-weight: 700;
			padding-bottom: 10px;
			line-height: 1.15em;
		}

		p {
			font-family: Arial, sans-serif;
			font-size: 14px;
			color: #222;
			line-height: 1.5em;
		}

		.material-icons {
			position: relative;
			top: 5px;
		}

		.mdl-textfield__label {
			top:13px;
		}

		.btn--primary {
			display: block;
		}
	}

}