/*
 * Flex UX Framework
 *
 * Filename: home.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.home {
	// .about-us {
 //    	margin: 12px 12px 32px 12px;

 //    	@include mq("phone-wide", "max"){
	// 		margin: 6px;
	// 	}
	// }
}