/*
 * Flex UX Framework
 *
 * Filename: home-layout-v4.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
$spotlight-tint-color: $brand-color;
 .home.layout-v4{
 	main{
 		background-color: #ffffff;
	 	.featured-spotlight{
			.page-header{
				display: flex;
				flex-flow: nowrap;

				@include mq("tablet", "max"){
					flex-direction:column-reverse;
					height: 100% !important;
				    min-height: unset !important;
				    max-height: unset !important;
				}

				&>div{
					&:first-of-type{
						height: inherit;
						@include mq("tablet", "max"){
							height: 287px;
						}
					}
				}

				.spotlight-hero{
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					
					height: inherit;
					padding: 40px 20px 20px;
					background-color: #53565A;
					color: white;
					font-size: 70px;

					@include mq("tablet", "max"){
						position:inherit;
					   height: 287px;
					   width: 100%;
					   opacity: 1;
					}

					@include mq("phone-wide", "max"){
						font-size: 60px;
					}

					&>div{
						display: flex;
						line-height: 0.8;
						&:first-of-type{
							font-size: 20px;
						}
					}

					button{
						// margin-top: 50px;
						color: white;
						background-color: $brand-color-btn-primary;
						min-height: 40px;
					}
				}

				#product-container{
					display: flex;
				    flex: 1 0 50%;
				    flex-flow: wrap;
					.grid{
						flex: 1 0 50%;
					  	&:nth-child(n+2){
						  	 .product-card--spotlight:after{
						    	content: '';
						    	background-color: rgba($spotlight-tint-color,0.5);
						    	height: 100%;
							    width: 100%;
							    opacity: 0.5;
							    position: absolute;
							    top: 0px;
							    left: 0px;
						    }
						}
					    &:nth-child(3n+1){
					    	.product-card--spotlight:after{
						    	content: '';
						    	background-color: rgba($spotlight-tint-color,0.2);
						    	height: 100%;
							    width: 100%;
							    opacity: 0.5;
							    position: absolute;
							    top: 0px;
							    left: 0px;
							}
					    }

						.product-card--spotlight{
							    flex: 1 0 100%;
							    min-width: 100%;
							    margin: 0px;
							    border-radius: 0px;
							    padding: 10px;
							    height: 50%;
							    min-height: 250px;
							    justify-content: center;

							    img{
							    	padding: 0px;
							    }

								a{
									position: absolute;
									z-index: 1;
									height: 100%;
									width: 100%;
									top: 0;
									left: 0;
							    }
						}
					}
				}
			}
	 	}

	 	.page-body{
	 		max-width: none;
	 		padding: 0;
	 		margin: 0;

			#featured-4{
				h1{
					margin:20px 0 10px;
				}

				.product-list{
					width: 101%;
					margin: 0px;
					border-top: 1px solid;
					.product-card{
						min-height: 259px;
						max-width: none;
						flex-basis: 20%;
						min-width: 200px;
						margin: 0px;
						border-right: 1px solid;
    					border-bottom: 1px solid;
						border-radius: 0;

						&:first-of-type {
					        justify-content: space-around;
							&>h1{
								font-size: 28px !important;
							}
						}
						@include mq("phone-wide", "max"){
							min-width: 150px;
							&:nth-child(n+3){
								display: inherit;
							}
							&:nth-child(n+5){
								display: none;
							}
						}

						.product-image{
							flex:inherit;
						}
					}
				}
			}

			.about-us{
				background: none;
				div.btn--primary{
					display: flex;
				}
			}	
	 	}

 	}
 }