/*
 * Flex UX Framework
 *
 * Filename: footer.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
.footer-grid {
	background: $footer-background;
	font-family: $standard-font-face;
	justify-content: center;

	h1 {
		letter-spacing: 0;
	}

	a {
		color:#ffffff !important;
	}
	.footer-grid-main {
		display:flex;
		flex-flow:row;
		justify-content:center;

	@include mq("tablet", "max") {
		flex-flow: column;
	}

		.col-right {
			display: flex;
			flex-flow: column;
			justify-content: space-between;

			.row {
				width: 461px;
				height: 76px;
				margin: 23px 10px 0px 0px;

				h1 {
					font-size: 26px;
					line-height: 28px;

					@include mq("tablet", "max") {
						font-size: 19px;
					}

					@include mq("phone-wide", "max") {
						font-size: 16px;
					}
				}

				&:nth-child(2) {
					margin: 0px 10px 24px 0px;

					h1 {
						font-size: 36px;

						@include mq("tablet", "max") {
							font-size: 30px;
						}

						@include mq("phone-wide", "max") {
							font-size: 27px;
						}
					}

					@include mq("tablet", "max") {
						margin: 23px auto 23px;
						width: 90%
					}
				}

				@include mq("tablet", "max") {
					margin: 23px auto 0px;
					width: 90%
				}

			}

			a {
				color:#fff;
				text-decoration: none;
				font-weight: 700;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.row {
			background: $footer-secondary-background;
			color: #fff;
			border-radius: 5px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			h1 {
				color: #fff;
				font-size: 22px;
				line-height:26px;
				font-weight: bold;
				margin:0;

				@include mq("phone-wide", "max") {
					font-size: 16px;
					line-height: 20px;
				}
			}
		}

		.col-left {

			.row {
				margin: 23px 24px 24px 10px;
				width: 463px;
				height: 176px;

				.repaddressinfo {
					font-size: 14px;
					line-height: 21px;

					@include mq("phone-wide", "max") {
						font-size: 12px;
						line-height: 18px;
					}

					p {
						margin:0;

					@include mq("phone-wide", "max") {
						font-size: 12px;
						line-height: 18px;
					}
					
					}

					a {
						color: #fff;
					}
				}

				h1 {
					margin-bottom: 13px;

					@include mq("tablet", "max") {
						font-size: 16px;
						line-height: 20px;
					}

					@include mq("phone-wide", "max") {
						font-size: 14px;
						line-height: 20px;
					}
				}

				@include mq("tablet", "max") {
					margin: 23px auto 0px;
					width: 90%
				}
			}
		}
	}

}



.footer {
	display: flex;
	flex-flow: row nowrap;	
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background: $footer-background;
	color: #fff;
	font-family: $standard-font-face;
	font-size: 16px;
	font-weight: 700;
	height: 25px;
	min-height: 25px;
	border-top: 1px solid $footer-secondary-background;

	@include mq("tablet", "max") {
		padding: 0;
		height: auto;
		min-height: auto;
	}

	@include mq("phone-wide", "max") {
		display:block;
	}
	
	img {
		height: 44px;
		margin-right: 20px;
		display: none;

		@include mq("tablet", "max") {
		    height: 25px;
		    margin-bottom: 20px;
		    margin-right: 0px;
		}

	}

	a {
		color: #fff !important;
    	text-decoration: none;
    	font-size: 16px;
    	font-weight: 700;

    	&:hover {
    		text-decoration: underline;
    	}
	}

	#call-link {
		display: none;
	}

	> div {
		display: flex;
		align-items: center;

		@include mq("tablet", "max") {
		    flex-direction: column;
		    justify-content: center;
		    height: 85px;
		    line-height: 1.5em;
		}
		@include mq("phone-wide", "max") {
    		// height: 30px;
    		padding-top: 25px;
    		height: auto;
    		padding-left: 2vw;
    		padding-right: 2vw;
		}

	}

	@include mq("tablet", "max") {
		min-height: 105px;
		flex-flow: column nowrap;
		text-align: center;
		padding: 30px;
		padding-bottom: 45px;

		@include mq("phone-wide", "max"){
			height: auto;
			min-height: auto;
			padding: 0;
		}

		#call-link {
			display: inline-flex;
			// padding-bottom: 24px;

			@include mq("phone-wide", "max"){
				// padding-bottom: 10px;
				padding-top: 30px;
			}

			& a {
				font-size: 20px;

				@include mq("phone-wide", "max") {
					font-size: 18px;
				}
			}
		}

		#call-link a {
			display: inline-flex;
			text-decoration: none;

			@include mq("phone-wide", "min") {
				margin-bottom: 15px;
			}
		}

		#call-link a::before {
			content: '\e0cd';
			height: 25px;
			width: 25px;
			font-family: "Material Icons";
			font-size: 24px;
			color: #c4c4c4;
			padding-right: 5px;
		}

		.footer-nav-menu {
			padding-top: 0;
			line-height: 2.5em;

			@include mq("phone-wide", "max") {
				padding-bottom: 45px;
				margin: 0;
			}
		}

	}
}

